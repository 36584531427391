import '@/styles/base.css'
import '@/styles/font.css'
import '@/styles/global.css'
import { v4 } from 'uuid'

const isServer = () => typeof window === 'undefined'

function MyApp({ Component, pageProps }) {
  if (!isServer()) {
    import('@/utils/adapt')
    try {
      if (!crypto.randomUUID) {
        crypto.randomUUID = v4
      }
    } catch (error) {}
  }

  return <Component {...pageProps} />
}

export default MyApp
